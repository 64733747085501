import React from "react";
import env from "./env";
import "./App.css";

function App() {
  const [greeting, setGreeting] = React.useState("");

  React.useEffect(() => {
    const host = env.host;
    const url = host + "/hello";
    fetch(url)
      .then((response) => response.json())
      .then((data) => setGreeting(data.response))
      .catch((error) => console.error(error));
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <img
          src="https://carsonkahn.com/uploads/Nylon-Icon.png"
          className="App-logo"
          alt="logo"
        />
        <p>{greeting !== "" ? "Server says: " + greeting : "Loading..."}</p>
        <a
          className="App-link"
          href="https://github.com/spindle-app/nylon"
          target="_blank"
          rel="noopener noreferrer"
        >
          Welcome to Nylon!
        </a>
      </header>
    </div>
  );
}

export default App;
